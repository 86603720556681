import { OrderedList, OrderedListItem } from 'Atoms/OrderedList';
import { P } from 'Atoms/text';
import { CompleteTooltip } from 'Atoms/tooltip/CompleteTooltip';
import { StyledLink } from 'Diagnose/molecules/diagnosisTable/DiagnosisTableDiseaseCell';
import { DynamicHtml } from 'Organisms/dynamicContent/DynamicHtml';
import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { DynamicContent } from 'types/dynamicContent';
import {
  RelatedDiseaseWithReference,
  VirusDrugWithReference,
  VirusReservoirEntity,
  VirusReservoirWithReference,
  VirusVaccinesWithReference,
  VirusVectorEntity,
  VirusVectorWithReference,
  VirusVehicleEntity,
  VirusVehicleWithReference,
} from 'types/microbeDetails';
import { getColumnCount, getWidth } from 'utils/getColumnWidthAndCount';

import { Section, StyledList } from './DiseaseSummarySection';

const Wrapper = styled.div``;

const Category = styled.div`
  margin: 30px 0;
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

const ReferenceWithLink = styled.div`
  display: flex;
`;

const StyledPBase = styled(P)`
  position: relative;
  display: inline-block;
  margin: 20px 0 0;
`;

const StyledP = styled(P)`
  margin-bottom: 10px;
`;

const SynonymsHeading = styled(StyledPBase)`
  margin: 10px 0;
`;

const StyledTooltip = styled(CompleteTooltip)`
  margin-top: -10px;
  margin-left: 1px;
`;

interface Props {
  type: DynamicContent;
  diseases: RelatedDiseaseWithReference[];
  reservoirs?: VirusReservoirWithReference[];
  vehicles?: VirusVehicleWithReference[];
  vectors?: VirusVectorWithReference[];
  vaccines?: VirusVaccinesWithReference[];
  drugs?: VirusDrugWithReference[];
  synonyms?: string[];
  etymology?: DynamicContent;
}

export const VirusSummarySection: FC<Props> = ({
  type,
  diseases,
  reservoirs,
  vaccines,
  vehicles,
  vectors,
  drugs,
  synonyms,
  etymology,
}) => {
  return (
    <Wrapper>
      {type && type.length > 0 && (
        <Category>
          <StyledP weight="700">
            Type
            <StyledTooltip content="Genome Features" />
          </StyledP>
          <DynamicHtml content={type} />
        </Category>
      )}
      {reservoirs && (
        <Category>
          <StyledP weight="700">
            Reservoirs
            <StyledTooltip content="Any animal, arthropod, plant, soil or substance in which an infectious agent normally lives and multiplies, on which it depends primarily for survival, and where it reproduces itself in such a manner that it can be transmitted to a susceptible host." />
          </StyledP>

          {reservoirs.length > 1 ? (
            <ul>
              {reservoirs.map((r: VirusReservoirWithReference) => (
                <li key={r.reservoir_class}>
                  {r.reservoir_class}
                  {!r.isReservoirEntityLatin && // this property checks if both entity and entity_latin is present
                    r.reservoirEntityArr.map(
                      (el: VirusReservoirEntity) =>
                        el.references.length > 0 && <DynamicHtml content={el.references} />
                    )}

                  {r.reservoirEntityArr.length && (
                    <ul>
                      {r.reservoirEntityArr.map(
                        (el: VirusReservoirEntity, index: number) =>
                          el.reservoir_entity && (
                            <li key={index}>
                              {el.reservoir_entity}{' '}
                              {el.reservoir_entity_latin && (
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: `(${el.reservoir_entity_latin})`,
                                  }}
                                />
                              )}
                              {el.references.length > 0 && <DynamicHtml content={el.references} />}
                            </li>
                          )
                      )}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          ) : reservoirs.length === 1 ? (
            <span>
              {/* When there is only one reservoir class present */}
              {reservoirs[0].reservoir_class}
              {!reservoirs[0].isReservoirEntityLatin &&
                reservoirs[0].reservoirEntityArr[0].references.length && (
                  <DynamicHtml content={reservoirs[0].reservoirEntityArr[0].references} />
                )}

              {reservoirs[0].reservoirEntityArr.length && (
                <ul>
                  {reservoirs[0].reservoirEntityArr.map(
                    (el: VirusReservoirEntity) =>
                      el.reservoir_entity && (
                        <li>
                          {' '}
                          {el.reservoir_entity}{' '}
                          {el.reservoir_entity_latin && (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: `(${el.reservoir_entity_latin})`,
                              }}
                            />
                          )}
                          {el.references.length > 0 && <DynamicHtml content={el.references} />}
                        </li>
                      )
                  )}
                </ul>
              )}
            </span>
          ) : (
            'Not applicable'
          )}
        </Category>
      )}
      {vehicles && (
        <Category>
          <StyledP weight="700">
            Vehicles
            <StyledTooltip content="The mode of transmission for an infectious agent. This generally implies a passive and inanimate (i.e., non-vector) mode." />
          </StyledP>
          {vehicles.length > 1 ? (
            <ul>
              {vehicles.map((v: VirusVehicleWithReference) => (
                <li key={v.vehicle_class}>
                  {v.vehicle_class}
                  {!v.isVehicleEntityLatin &&
                    v.vehicle_entity_arr.map(
                      (el: VirusVehicleEntity) =>
                        el.references.length > 0 && <DynamicHtml content={el.references} />
                    )}
                  {v.vehicle_entity_arr.length && (
                    <ul>
                      {v.vehicle_entity_arr.map(
                        (el: VirusVehicleEntity) =>
                          el.vehicle_entity && (
                            <li>
                              {' '}
                              {el.vehicle_entity}{' '}
                              {el.vehicle_entity_latin && (
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: `(${el.vehicle_entity_latin})`,
                                  }}
                                />
                              )}
                              {el.references.length > 0 && <DynamicHtml content={el.references} />}
                            </li>
                          )
                      )}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          ) : vehicles.length === 1 ? (
            <span>
              {/* When there is only one vehicle class present */}
              {vehicles[0].vehicle_class}
              {vehicles[0].vehicle_entity_arr[0].references.length && (
                <DynamicHtml content={vehicles[0].vehicle_entity_arr[0].references} />
              )}

              {vehicles[0].vehicle_entity_arr.length && (
                <ul>
                  {vehicles[0].vehicle_entity_arr.map(
                    (el: VirusVehicleEntity) =>
                      el.vehicle_entity && (
                        <li>
                          {' '}
                          {el.vehicle_entity}{' '}
                          {el.vehicle_entity_latin && (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: `(${el.vehicle_entity_latin})`,
                              }}
                            />
                          )}
                          {el.references.length > 0 && <DynamicHtml content={el.references} />}
                        </li>
                      )
                  )}
                </ul>
              )}
            </span>
          ) : (
            'Not applicable'
          )}
        </Category>
      )}
      {vectors && (
        <Category>
          <StyledP weight="700">
            Vector
            <StyledTooltip content="An arthropod or other living carrier which transports an infectious agent from an infected organism or reservoir to a susceptible individual or immediate surroundings." />
          </StyledP>
          {vectors && vectors.length > 1 ? (
            <ul>
              {vectors.map((v: VirusVectorWithReference) => (
                <li key={v.vector_class}>
                  {v.vector_class}
                  {!v.isVectorEntityLatin && // this property is checking if there is vector_entity and vector_entity_latin both are present
                    v.vector_entity_arr.map(
                      (el: VirusVectorEntity) =>
                        el.references.length > 0 && <DynamicHtml content={el.references} />
                    )}
                  {v.vector_entity_arr.length && (
                    <ul>
                      {v.vector_entity_arr.map((el: VirusVectorEntity) =>
                        el.vector_entity_latin && el.vector_entity ? (
                          <li>
                            {el.vector_entity}{' '}
                            {el.vector_entity_latin && (
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: `(${el.vector_entity_latin})`,
                                }}
                              />
                            )}
                            {el.references.length > 0 && <DynamicHtml content={el.references} />}
                          </li>
                        ) : (
                          <>
                            {el.vector_entity_latin && (
                              <li>
                                {' '}
                                {el.vector_entity_latin}
                                {el.references.length > 0 && (
                                  <DynamicHtml content={el.references} />
                                )}
                              </li>
                            )}
                          </>
                        )
                      )}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          ) : vectors.length === 1 ? (
            <span>
              {/* When there is only one vector class present */}
              {vectors[0].vector_class}
              {!vectors[0].isVectorEntityLatin &&
                vectors[0].vector_entity_arr[0].references.length && (
                  <DynamicHtml content={vectors[0].vector_entity_arr[0].references} />
                )}
              {vectors[0].vector_entity_arr.length && (
                <ul>
                  {vectors[0].vector_entity_arr.map(
                    (el: VirusVectorEntity) =>
                      el.vector_entity && (
                        <li>
                          {' '}
                          {el.vector_entity}{' '}
                          {el.vector_entity_latin && (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: `(${el.vector_entity_latin})`,
                              }}
                            />
                          )}
                          {el.references.length > 0 && <DynamicHtml content={el.references} />}
                        </li>
                      )
                  )}
                </ul>
              )}
            </span>
          ) : (
            'Not applicable'
          )}
        </Category>
      )}
      {drugs && (
        <Category>
          <StyledP weight="700">
            Drugs
            <StyledTooltip content="Drugs which have been advocated for the therapy of infection caused by the virus." />
          </StyledP>
          {drugs.length > 0
            ? drugs.map(d => (
                <ReferenceWithLink key={d.drug_code}>
                  <StyledLink key={d.drug_code} to={`/explore/drugs/${d.drug_code}`}>
                    {d.drug}{' '}
                  </StyledLink>
                  {d.references.length > 0 && <DynamicHtml content={d.references} />}
                </ReferenceWithLink>
              ))
            : 'None'}
        </Category>
      )}
      {vaccines && (
        <Category>
          <StyledP weight="700">
            Vaccines
            <StyledTooltip content="Vaccines and globulin preparations." />
          </StyledP>
          {vaccines.length > 0
            ? vaccines.map(d => (
                <ReferenceWithLink key={d.vaccine_code}>
                  <StyledLink key={d.vaccine_code} to={`/explore/vaccines/${d.vaccine_code}`}>
                    {d.vaccine}{' '}
                  </StyledLink>
                  {d.references.length > 0 && <DynamicHtml content={d.references} />}{' '}
                </ReferenceWithLink>
              ))
            : 'None'}
        </Category>
      )}
      <Category>
        {etymology && etymology.length > 0 && (
          <Section>
            <SynonymsHeading weight="700">Etymology</SynonymsHeading>
            <div>
              <DynamicHtml content={etymology} />
            </div>
          </Section>
        )}
      </Category>
      {diseases && diseases.length > 0 && (
        <Section>
          <StyledP weight="700">
            {diseases.length > 1
              ? `Associated Diseases (${diseases.length})`
              : 'Associated Disease'}
          </StyledP>
          <StyledList
            key={'associated-disease-list'}
            containerWidth={getWidth(diseases.length)}
            columnCount={getColumnCount(diseases.length)}
          >
            {diseases.map(x => (
              <Section key={x.disease}>
                <StyledLink key={x.disease_code} to={`/explore/diseases/${x.disease_code}`}>
                  {x.disease}
                </StyledLink>
              </Section>
            ))}
          </StyledList>
        </Section>
      )}
      {synonyms && synonyms.length > 0 && (
        <Category>
          <StyledP weight="700">Related terms ({synonyms.length})</StyledP>
          <OrderedList>
            {synonyms.map((s: string) => (
              <OrderedListItem key={s}>{s}</OrderedListItem>
            ))}
          </OrderedList>
        </Category>
      )}
    </Wrapper>
  );
};
